<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton :classProp="'primary'" :isDisabled="!isDirty" @vasionButtonClicked="save()">
          Save
        </VasionButton>
        <VasionButton class="last-btn" :classProp="'secondary'" @vasionButtonClicked="goToScheduleOcrList()">
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <VasionSnackbar
      id="edit-ocr-snackbar"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <AutomationEngineSharedHeader
      ref="frequencyComponent"
      nameLabel="Name"
      @permissions="showMainSection = false"
      @general-automation="showMainSection = true"
      @dirty="isDirty = true"
    />
    <div v-if="showMainSection" id="build-ocr" class="collapsable-section">
      <VasionButton
        name="collapse-section"
        class="collapse-section"
        :icon="showBuildOcr ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
        title="Collapse Section"
        @vasionButtonClicked="showBuildOcr = !showBuildOcr"
      />
      <h2 class="subheader">
        Build OCR
      </h2>
    </div>
    <div v-if="showMainSection" v-show="showBuildOcr">
      <div class="row">
        <VasionDropList
          v-slot="slotItem"
          v-model="attributeForm"
          :dataArray="attributeFormList"
          width="396"
          :title="`${$formsLabel.toUpperCase()}`"
          placeholder="..."
          type="plain-list"
          displayName="name"
          valueName="value"
          @input="isDirty = true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>

        <VasionInput
          id="process-folder"
          v-model="selectedProcessFolder"
          :readonly="true"
          class="field-horizontal-spacer"
          title="PROCESS FOLDER"
          inputType="top-white"
          name="process-folder"
          placeholder="Select Folder..."
          :width="'306'"
          :isInErrorState="processFolderError"
        />
        <VasionButton
          id="process-folder-button"
          class="browse-button"
          :classProp="'primary'"
          @vasionButtonClicked="toggleBrowseFolderDialog('process-folder')"
        >
          Browse
        </VasionButton>

        <VasionCheckbox
          id="include-subfolders"
          name="include-subfolders"
          class="row-checkbox"
          :checked="includeSubfolders"
          @change="includeSubfolders = !includeSubfolders; isDirty = true"
        >
          Include Subfolders
        </VasionCheckbox>
      </div>
      <div class="row">
        <VasionCheckbox
          id="new-documents-only"
          name="new-documents-only"
          class="row-checkbox"
          :checked="newDocumentsOnly"
          @change="newDocumentsOnly = !newDocumentsOnly; isDirty = true"
        >
          New Document Only
        </VasionCheckbox>
      </div>
      <div class="row">
        <VasionCheckbox
          id="move-completed-documents-checkbox"
          name="move-completed-documents-checkbox"
          class="row-checkbox"
          :checked="moveCompletedDocuments"
          @change="changeMoveCompletedDocumentsStatus"
        >
          Move Completed Documents
        </VasionCheckbox>
        <VasionInput
          id="move-to-folder"
          v-model="selectedMoveToFolder"
          title="MOVE TO FOLDER"
          :readonly="true"
          inputType="top-white"
          name="move-to-folder"
          placeholder="Select Folder..."
          :width="'306'"
          :isDisabled="!moveCompletedDocuments"
          :isInErrorState="moveFolderError"
        />
        <VasionButton
          id="move-to-folder-button"
          class="browse-button"
          :classProp="'primary'"
          :isDisabled="!moveCompletedDocuments"
          @vasionButtonClicked="toggleBrowseFolderDialog('move-to-folder')"
        >
          Browse
        </VasionButton>
      </div>
      <div class="row">
        <VasionCheckbox
          id="convert-to-searchable-pdf-only"
          name="convert-to-searchable-pdf-only"
          class="row-checkbox"
          :checked="convertToSearchablePdf"
          @change="checkRequirements"
        >
          Convert to Searchable PDF
        </VasionCheckbox>
      </div>
    </div>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionGeneralModal
      :showRejectButton="false"
      :confirmButtonText="'CLOSE'"
      :modalType="'slot'"
      :sync="showSearchablePDFsRequirements"
      @confirmButtonClick="showSearchablePDFsRequirements = false;"
    >
      <div v-if="meessageType === 'move'">
        <p>To successfully transform the documents into <b>Searchable PDFs</b>, it is imperative that the completed documents be moved.</p>
        <p>Please do the following:</p>
        <ol>
          <li>Select the <b>Move Completed Documents</b> checkbox</li>
          <li>Choose the desired <b>MOVE TO FOLDER</b></li>
        </ol>
      </div>
      <p v-if="meessageType === 'searchable'">Before deselecting this option, it is necessary to deactivate the <b>Convert to Searchable PDF</b> option.</p>
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'CONFIRM'"
      :modalType="'slot'"
      :sync="showBrowseFolder"
      @confirmButtonClick="folderDialogOK()"
      @noButtonClick="showBrowseFolder = false"
    >
      <div id="browse-folders-container">
        <VasionFolders @itemSelected="folderSelected = true" />
      </div>
    </VasionGeneralModal>
    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    </div>
  </div>
</template>

<script>
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'EditScheduledOcr',
  components: {
    AutomationEngineSharedHeader,
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data() {
    return {
      attributeForm: '',
      convertToSearchablePdf: false,
      fileBrowseField: '',
      folderSelected: false,
      includeSubfolders: false,
      indexForms: [],
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      meessageType: '',
      moveCompletedDocuments: false,
      moveFolderError: false,
      newDocumentsOnly: false,
      processFolderError: false,
      schedulerId: 0,
      schedulerTitle: '',
      selectedMoveToFolder: '',
      selectedMoveToFolderId: null,
      selectedProcessFolder: '',
      selectedProcessFolderId: null,
      showBrowseFolder: false,
      showBuildOcr: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSearchablePDFsRequirements: false,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    searchablePDFsRequirementsMeeted() {
      return this.moveCompletedDocuments && this.selectedMoveToFolder !== "" && this.selectedMoveToFolderId !== null
    },
    selectedFolder() { return this.$store.state.common.selectedFolder },
  },
  async created() {
    this.indexForms = await this.$store.dispatch('attributeForm/getForms')

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getOcrScheduler', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        this.setSchedulerValues()
        this.setHeaderDetails(this.schedulerId, this.activeScheduler?.runDetails)
      }
    }
    this.schedulerTitle = this.schedulerId && this.schedulerId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Schedule OCR'
  },
  methods: {
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    changeMoveCompletedDocumentsStatus() {
      if (this.moveCompletedDocuments && this.convertToSearchablePdf) {
        this.meessageType = 'searchable'
        this.showSearchablePDFsRequirements = true
        return
      }
      this.moveCompletedDocuments = !this.moveCompletedDocuments
      if (!this.moveCompletedDocuments) {
        this.selectedMoveToFolder = ''
        this.selectedMoveToFolderId = null
      }
      this.isDirty = true
    },
    checkRequirements() {
      if (!this.searchablePDFsRequirementsMeeted && !this.convertToSearchablePdf) {
        this.meessageType = 'move'
        this.showSearchablePDFsRequirements = true
        return
      }
      this.convertToSearchablePdf = !this.convertToSearchablePdf
      this.isDirty = true
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK() {
      if (this.selectedFolder && this.selectedFolder.item.FolderId > 0 && this.selectedFolder.isSelected === true) {
        this.isDirty = true
        if (this.fileBrowseField === 'process-folder') {
          this.selectedProcessFolderId = this.selectedFolder.item.FolderId
          this.selectedProcessFolder = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'move-to-folder') {
          this.selectedMoveToFolderId = this.selectedFolder.item.FolderId
          this.selectedMoveToFolder = this.selectedFolder.item.Name
        }
      } else {
        this.selectedProcessFolderId = null
        this.selectedProcessFolder = ''

        this.selectedMoveToFolderId = null
        this.selectedMoveToFolder = ''
      }

      this.showBrowseFolder = false
      this.$store.dispatch('common/setSelectedFolder', null)
    },
    goToScheduleOcrList() { this.$router.push({ name: 'ScheduledOcr' }) },
    async save() {
      this.isLoading = true
      if (this.moveCompletedDocuments === true && this.selectedMoveToFolderId === null) {
        this.showBuildOcr = true
        this.moveFolderError = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Please Select a MOVE TO FOLDER'
        this.snackbarImage = false
        this.showSnackbarBool = true
        this.isLoading = false
        return
      }
      if (this.selectedProcessFolderId === null) {
        this.showBuildOcr = true
        this.processFolderError = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Please Select a Process Folder'
        this.snackbarImage = false
        this.showSnackbarBool = true
        this.isLoading = false
        return
      }
      const { frequencyComponent } = this.$refs
      await frequencyComponent.validate()
      if (frequencyComponent.errors !== '') {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = frequencyComponent.errors
        this.snackbarImage = false
        this.showSnackbarBool = true
        this.isLoading = false
        return
      }
      const payload = {
        runDetails: await frequencyComponent.getValues(),
        objectID: this.attributeForm.value,
        folderID: this.selectedProcessFolderId,
        includeSubfolders: this.includeSubfolders,
        moveCompletedDocumentsFolderID: this.selectedMoveToFolderId,
        newDocumentsOnly: this.newDocumentsOnly,
        convertToSearchablePdf: this.convertToSearchablePdf,
      }
      const response = await this.$store.dispatch('admin/saveOcrTemplate', payload)
      this.isLoading = false
      if (response && response.Value > 0) {
        this.isDirty = false
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'OCR Scheduled'
        this.snackbarImage = true
        this.showSnackbarBool = true
        setTimeout(() => {
          this.goToScheduleOcrList()
        }, 1500)
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was a problem saving'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
    setHeaderDetails(id, details) {
      const { frequencyComponent } = this.$refs
      if (id > 0) {
        frequencyComponent.setValues(details)
      } else {
        frequencyComponent.setValues()
      }
    },
    setSchedulerValues() {
      this.schedulerId = this.activeScheduler.runDetails.schedulerID
      this.selectedProcessFolder = this.activeScheduler.folderName
      this.attributeForm = this.attributeFormList.find(a => a.value === this.activeScheduler.objectID) || ''
      this.selectedMoveToFolderId = this.activeScheduler.moveCompletedDocumentsFolderID
      this.selectedMoveToFolder = this.activeScheduler.moveCompletedDocumentsFolderName
      this.newDocumentsOnly = this.activeScheduler.newDocumentsOnly
      this.includeSubfolders = this.activeScheduler.includeSubfolders
      this.selectedProcessFolderId = this.activeScheduler.folderID
      this.moveCompletedDocuments = this.selectedMoveToFolderId && this.selectedMoveToFolderId > 0
      this.convertToSearchablePdf = this.activeScheduler.convertToSearchablePdf
    },
    toggleBrowseFolderDialog(field) {
      this.folderSelected = false
      this.showBrowseFolder = !this.showBrowseFolder

      this.fileBrowseField = ''
      if (this.showBrowseFolder && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .collapsable-section {
    display: flex;
    flex-direction: row;

    .subheader {
      @include SubHeadline;
      width: 200px;
      display: block;
      float: left;
      margin-top: 5px;
    }
  }

    .collapse-section {
      background-color: $grey-50;
      border-radius: 8px;
      width: 32px;
      height: 32px;
      margin: 0 16px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .collapse-section:hover {
      border-radius: 8px;
      background-color: $grey-200;
    }

    .row{
      display: flex;
      flex-direction: row;
      margin: 0px;
    }

    .browse-button {
        padding-top: 17px;
      }

    #browse-folders-container {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .row-checkbox {
      padding-top: 29px;
      padding-bottom: 5px;
      width: 230px;
    }

    .field-horizontal-spacer{
      padding-left: 12px;
    }

</style>
